import React from 'react'

export default function WhatWeDo() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/what-does.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">What Does</p>
          <h1 className="skills-section--heading"><span className='underline-green'>ATS</span> Wealth Managers do?</h1>
          <p className="hero--section-description">
          ATS wealth managers manages funds for High Net-Worth Individuals (HNI) and companies. We make well-timed investment decisions on behalf of our clients to grow their finances and portfolio
          </p>
          <p className="hero--section-description">
          We collect money from private investors and invest in almost anything that fund managers sees fit. The choice of investments ranges from Startup Investing, Venture Capital, Private equity, equity, bonds and commodity. 
          </p>
        </div>
      </div>
    </section>
  )
}
