import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function OurPhilosophy() {
  return (
    <section id="OurPhilosophy" className="contact--section">
        <Container>
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <h1 className="skills-section--heading mb-30"><span className='underline-green'>Our Philosophy</span></h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} lg={4}>
                    <div className="skills--section--container">
                        <div className="skills--section--card-one">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title text-left">Our Belief</h3>
                                <p className="skills--section--description text-left">We deeply appreciate the dedication and hard work our clients have invested in accumulating their wealth. Therefore, it is our unwavering commitment to reciprocate with an equal level of diligence and dedication in preserving and safeguarding their financial assets. Our team invests significant time and effort to ensure that our clients' hard- earned money is not only kept secure but also carefully nurtured and grown</p>
                                <p className='text-left mt-20 mb-20'>Rule No. 1 :<br /> <b>"Not to lose client’s hard-earned money"</b></p>
                                <p className='text-left'>Rule No. 2 :<br /> <b>"Do not forget Rule number 1"</b></p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="skills--section--container">
                        <div className="skills--section--card-one">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title text-left">Our Approach</h3>
                                <p className="skills--section--description text-left">We firmly believe that achieving alpha returns consistently over an extended period necessitates a balanced combination of equity and derivatives in our investment strategy. As most of the investments are in Index Stocks, replicating market returns becomes easier but with randomness in the market one could generate Alpha returns by investing in a few stocks in the short run.</p>
                                <p className="skills--section--description text-left">For long-term outperformance, we need to have a mix of both Quality Equity Investment clubbed with exposure to derivatives.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="skills--section--container">
                        <div className="skills--section--card-one">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title text-left">Growth In Consumption</h3>
                                <p className="skills--section--description text-left">India’s current per capita income, while compared to several other western countries, is way lesser. As the per capita income increases, focus will shift from basic necessity to several other consumption-led items. There will be more number of people buying cars, houses, jewellery, watches, premium clothing, and they will travel with higher disposable incomes. Consumption industry in rn countries is vast; there is no reason why India cannot match up to their consumption l levels, with income levels improving every year. “Those bathing in Lifebuoy will soon buy Lux.”</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={12} lg={12} className='mt-30'>
                    <div className="skills--section--container">
                        <div className="skills--section--card">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title text-left">India’s Growth Story</h3>
                                <p className="skills--section--description text-left">India’s current per capita income, while compared to several other western countries, is way lesser. As the per capita income increases, focus will shift from basic necessity to several other consumption-led items. There will be more number of people buying cars, houses, jewellery, watches, premium clothing, and they will travel with higher disposable incomes. Consumption industry in rn countries is vast; there is no reason why India cannot match up to their consumption l levels, with income levels improving every year. “Those bathing in Lifebuoy will soon buy Lux.”</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>        
  )
}
