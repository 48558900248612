import React from 'react'

export default function Team() {
  return (
    <section id="Team" className="contact--section">
        <div>
            <h2>Team</h2>
            <p className="text-md">
                ATS offers PMS Service where professional portfolio managers, backed by efficient and experienced researchers manage your investments with an aim to deliver consistent returns. We offer tailor-made solutions to meet every client’s financial objective. We at ATS understand that each individual has different financial goals, and so the portfolio managers offer flexible and customized investment strategies pertaining to each client’s portfolio.
            </p>
        </div>
    </section>    
  )
}
