export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          {/* <p className="section--title">Hey, I'm John</p> */}
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">ATS <span className="navbar--active-content">Wealth</span></span>{" "}
            <br />
            Managers
          </h1>
          <p className="hero--section-description">
            Wealth Managed Well
          </p>
        </div>
        <button className="btn btn-primary">Get Started</button>
      </div>
      <div className="hero--section--img">
        <img src="./img/banner-img1.png" alt="Hero Section" />
      </div>
    </section>
  );
}
