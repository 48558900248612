import React from 'react'

export default function Location() {
  return (
    <section className="conatc--section1">
        <div className='google-map'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.2985810880823!2d77.68095097508534!3d12.933253515717842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16bb1fa47053%3A0xb24255b4d406d22!2sATS%20Share%20Brokers%20Pvt%20Ltd.!5e1!3m2!1sen!2sin!4v1701014350465!5m2!1sen!2sin" className='gmap'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </section>
  )
}
