import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function WhatWeOffer() {
  return (
    <section id="Services" className="contact--section">
        <Container>
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <h1 className="skills-section--heading mb-30"><span className='underline-green'>What We Offer</span></h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} lg={4}></Col>
                <Col xs={12} md={4} lg={4} className='text-center'>
                    <div className="skills--section--container">
                        <div className="skills--section--card">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title">Private Equity</h3>
                                <p className="skills--section--description">Private equity (PE) is a young and vibrant area of growth, our PE investment experience spans across stages from early- & mid-stage to late stage & pre-IPO investing along with thematic investing.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}></Col>
            </Row>
            <Row>
                <Col xs={12} md={4} lg={4}>
                    <div className="skills--section--container mb-30">
                        <div className="skills--section--card">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title">Venture Capital</h3>
                                <p className="skills--section--description">Our venture capital funds and special purpose vehicles provide investment access to early and growth-stage companies in the private markets.</p>
                            </div>
                        </div>
                    </div>
                    <div className="skills--section--container">
                        <div className="skills--section--card">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title">Equity</h3>
                                <p className="skills--section--description">Our PMS services provide clients with tailored investment strategies in the equity markets. Our expert portfolio managers design and manage portfolios of publicly traded stocks to help clients achieve their financial goals.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4} className='text-center'>
                <img src="./img/what-we-offer.png" alt="Our Services" />
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="skills--section--container mb-30">
                        <div className="skills--section--card">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title">Managed Account</h3>
                                <p className="skills--section--description">Fast changing regulations, technology and talent demands can be challenging and inefficient. ATS Wealth can help reimagine your finances and accounting operational model.</p>
                            </div>
                        </div>
                    </div>
                    <div className="skills--section--container">
                        <div className="skills--section--card">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title">Commodity</h3>
                                <p className="skills--section--description">We offer clients the opportunity to diversify their portfolios with commodity investments. Our experienced portfolio managers handle investments in physical assets like precious metals, agricultural products, and energy resources to help clients navigate commodity markets effectively.</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} lg={4}></Col>
                <Col xs={12} md={4} lg={4} className='text-center'>
                    <div className="skills--section--container">
                        <div className="skills--section--card">
                            <div className="skills--section--card--content">
                                <h3 className="skills--section--title">Bonds</h3>
                                <p className="skills--section--description">We offer a range of bond investment strategies, including government bonds, corporate bonds, and municipal bonds, all designed to optimize risk and return for our clients.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}></Col>
            </Row>
        </Container>
    </section>
  )
}
