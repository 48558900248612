import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Contact() {
  return (
    <section id="Contact" className="conatc--section">
      <div className="about--section--img">
        <h2 className='contact-heading'>Lets talk with<br />
          our awesome<br />
          team</h2>
        <p className="text-lg">
          Wealth Managed Well
        </p>

        <div className='grid mt-30'>
          <div className='grid-img'>
            <img src="./img/call.png" alt="Call" />
          </div>
          <div className='grid-content'>
            <h3>Call</h3>
            <p>+91 85534 65134</p>
          </div>
        </div>

        <div className='grid mt-30'>
          <div className='grid-img'>
            <img src="./img/email.png" alt="Email" />
          </div>
          <div className='grid-content ml-0 mt-10'>
            <h3>Email</h3>
            <p>support@adityatrading.com</p>
          </div>
        </div>

        <div className='grid mt-30'>
          <div className='grid-img'>
            <img src="./img/map.png" alt="Email" />
          </div>
          <div className='grid-content'>
            <h3>Location</h3>
            <p className='location-txt'>
              ATS Wealth Managers, 1st Floor, 2gethr ORR,<br />
              Tower B, Mantri Commercio, Devarabisanahalli,<br />
              Near Sakra World Hospital, Kariyammana Agrahara,<br />
              Bellandur, Bengaluru, Karnataka 560103
            </p>
          </div>
        </div>

      </div>
      <div className="hero--section--content--box about--section--box">
        <form className="contact--form--container">
          <div className="container">
            <label htmlFor="first-name" className="contact--label">
              <span className="text-md">Name</span>
              <input
                type="text"
                className="contact--input text-md"
                name="first-name"
                id="first-name"
                placeholder='Full Name here'
                required
              />
            </label>
            <label htmlFor="email" className="contact--label">
              <span className="text-md">Email</span>
              <input
                type="email"
                className="contact--input text-md"
                name="email"
                id="email"
                placeholder='email address here'
                required
              />
            </label>
            <label htmlFor="phone-number" className="contact--label">
              <span className="text-md">phone-number</span>
              <input
                type="number"
                className="contact--input text-md"
                name="phone-number"
                id="phone-number"
                placeholder='Contact number here'
                required
              />
            </label>
          </div>
          <label htmlFor="message" className="contact--label">
            <span className="text-md">Message</span>
            <textarea
              className="contact--input text-md"
              id="message"
              rows="8"
              placeholder="Type your message..."
            />
          </label>
          <div className='mt-30'>
            <button className="btn btn-primary contact--form--btn">Submit</button>
          </div>
        </form>
      </div>
    </section>
  )
}
